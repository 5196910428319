<template>
  <keep-alive v-if="$route.meta.keepalive">
    <router-view />
  </keep-alive>
  <router-view v-else />
</template>

<script>
export default {
  name: "index"
};
</script>

<style scoped></style>
